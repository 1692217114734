import { View, Text } from 'react-native';
import { WithSkiaWeb } from '@shopify/react-native-skia/lib/module/web';

export default function Skia() {
  return (
    <WithSkiaWeb
      getComponent={() => import('./Draw')}
      fallback={
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Text>Loading...</Text>
        </View>
      }
    />
  );
}
