import { StyleSheet, View, Platform } from 'react-native';
import Constants from 'expo-constants';
import Skia from './src/Components/Designer/Skia';

export default function App() {
  return (
    <View style={styles.appContainer}>
      <View style={styles.canvasContainer}>
        <Skia />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
    paddingTop: Constants.statusBarHeight + 12,
  },
  canvasContainer: {
    backgroundColor: 'grey',
    width: Platform.OS === 'web' ? 1300 : 360,
    height: Platform.OS === 'web' ? 1000 : 270,
  },
});
